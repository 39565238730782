export const columnsDataOverview = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "SALES",
    accessor: "sales",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "PRICE",
    accessor: "price",
  },
  {
    Header: "RATING",
    accessor: "rating",
  },
  {
    Header: " ",
    accessor: "editable",
  },
];
