export const tableColumnsLastOffer = [
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "USD Price",
    accessor: "usd price",
  },
  {
    Header: "Expiration",
    accessor: "expiration",
  },
  {
    Header: "From",
    accessor: "from",
  },
];
