export const columnsDataUserReports = [
  {
    Header: "USER NAME",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "JOIN DATE",
    accessor: "date",
  },
];
